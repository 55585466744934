import {
  Payment as PaymentType,
  PaymentMethodGroup as PaymentMethodGroupType,
  PaymentStatus as PaymentStatusType
} from '@/types'

import CurrencyField from './CurrencyField'
import DateField from './DateField'
import PercentageField from './PercentageField'

interface Props {
  payment: PaymentType
  method: PaymentMethodGroupType
  status?: PaymentStatusType
}

export default function PaymentMethodFeedbacks({ payment, method, status }: Props) {
  const {
    fundingInstrument,
    creditCard,
    total,
    authDate,
    dueDate,
    installments,
    installmentAmount,
    latePaymentFine,
    monthlyInterestRate
  } = payment || {}
  const { lastDigits, firstDigits } = fundingInstrument || {}
  const { lastDigits: tempLastDigits, firstDigits: tempFirstDigits } = creditCard || {}

  const values = {
    total: (
      <strong>
        <CurrencyField value={total} />
      </strong>
    ),
    authDate: (
      <strong>
        <DateField type="datetime" value={authDate} />
      </strong>
    ),
    dueDate: (
      <strong>
        <DateField value={dueDate} />
      </strong>
    ),
    firstDigits: <strong>{firstDigits || tempFirstDigits}</strong>,
    lastDigits: <strong>{lastDigits || tempLastDigits}</strong>,
    installments: <strong>{installments}</strong>,
    installmentAmount: (
      <strong>
        <CurrencyField value={installmentAmount} />
      </strong>
    ),
    latePaymentFine: (
      <strong>{latePaymentFine && <CurrencyField value={latePaymentFine} />}</strong>
    ),
    monthlyInterestRate: (
      <strong>{monthlyInterestRate && <PercentageField value={monthlyInterestRate} />}</strong>
    )
  }

  const feedbacks: any = {
    bankDebit: {
      analysis: <></>,
      'not-authorized': <></>,
      reversed: <></>,
      created: (
        <>
          Agendamos esta cobrança no valor de {values.total} para pagamento através do Internet
          Banking até dia {values.dueDate}.
        </>
      ),
      waiting: (
        <>
          Você deve pagar agora, através do Internet Banking, esta cobrança no valor de{' '}
          {values.total} com vencimento até o dia {values.dueDate}. Caso o pagamento não seja
          realizado, esta cobrança poderá ser cancelada.
        </>
      ),
      authorized: (
        <>
          Recebemos em {values.authDate} o valor {values.total} com vencimento previsto até{' '}
          {values.dueDate}.
        </>
      ),
      'partially-refunded': (
        <>
          Fizemos o reembolso parcial da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      refunded: (
        <>
          Fizemos o reembolso total da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      cancelled: (
        <>
          Houve o cancelamento da cobrança do valor {values.total} e vencimento em {values.dueDate}.
          Aguarde o crédito do valor em sua conta bancária informada durante a solicitação de
          cancelamento.
        </>
      ),
      error: (
        <>
          Erro enquanto processávamos o pagamento no valor {values.total} com vencimento em{' '}
          {values.dueDate}. Vamos verificar a causa antes de tentar novamente. Caso tenha dúvidas,
          entre em contato com nossa equipe de atendimento.
        </>
      )
    },
    billingNote: {
      analysis: <></>,
      'not-authorized': <></>,
      reversed: <></>,
      created: (
        <>
          Agendamos esta cobrança no valor de {values.total} para pagamento no boleto bancário até
          dia {values.dueDate}.
        </>
      ),
      waiting: (
        <>
          Você deve pagar este boleto bancário no valor de {values.total} até o dia {values.dueDate}
          . Caso o pagamento não seja realizado, este boleto poderá ser cancelado.
        </>
      ),
      authorized: (
        <>
          Recebemos em {values.authDate} o valor {values.total} com vencimento previsto até{' '}
          {values.dueDate}.
        </>
      ),
      'partially-refunded': (
        <>
          Fizemos o reembolso parcial da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      refunded: (
        <>
          Fizemos o reembolso total da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      cancelled: (
        <>
          Houve o cancelamento da cobrança do valor {values.total} e vencimento em {values.dueDate}.
        </>
      ),
      error: (
        <>
          Erro enquanto processávamos o pagamento no valor {values.total} com vencimento em{' '}
          {values.dueDate}. Vamos verificar a causa antes de tentar novamente. Caso tenha dúvidas,
          entre em contato com nossa equipe de atendimento.
        </>
      )
    },
    creditCard: {
      created: (
        <>
          Agendamos a cobrança do valor {values.total} no dia {values.dueDate} no cartão de crédito{' '}
          {values.firstDigits} *** {values.lastDigits} em {values.installments} parcela(s) no valor
          de {values.installmentAmount}. Caso o pagamento não seja realizado por falta de limite ou
          dados inválidos, você será notificado.
        </>
      ),
      queued: <>Aguarde alguns instantes. O seu pagamento está sendo processado.</>,
      waiting: (
        <>
          Aguarde! Em alguns instantes vamos cobrar o valor de {values.total} no cartão de crédito{' '}
          {values.firstDigits} *** {values.lastDigits} em {values.installments} parcela(s) no valor
          de {values.installmentAmount}. Caso o pagamento não seja realizado por falta de limite ou
          dados inválidos, você será notificado.
        </>
      ),
      analysis: (
        <>
          Seu pagamento está em análise! O valor {values.total} foi processado no dia{' '}
          {values.dueDate} no cartão de crédito {values.firstDigits} *** {values.lastDigits} em{' '}
          {values.installments} parcela(s) no valor de {values.installmentAmount}. Aguarde a
          confirmação de cobrança.
        </>
      ),
      authorized: (
        <>
          Cobramos com sucesso! O valor {values.total} foi cobrado no dia {values.authDate} no
          cartão de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}.
        </>
      ),
      'not-authorized': (
        <>
          Não conseguimos autorização para cobrança do valor {values.total} no dia {values.dueDate}{' '}
          no cartão de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}. Tente novamente ou altere sua forma de
          pagamento.
        </>
      ),
      'partially-refunded': (
        <>
          Fizemos o reembolso parcial da cobrança no valor {values.total} em {values.authDate} no
          cartão de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}.
        </>
      ),
      refunded: (
        <>
          Fizemos o reembolso total da cobrança no valor {values.total} em {values.authDate} no
          cartão de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}.
        </>
      ),
      reversed: (
        <>
          Houve o estorno total da cobrança no valor {values.total} em {values.authDate} no cartão
          de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}. Aguarde o prazo da operadora de cartão
          de crédito para estorno dos valores em sua próxima fatura.
        </>
      ),
      cancelled: (
        <>
          Houve o cancelamento da cobrança do valor {values.total} em {values.dueDate}.
        </>
      ),
      error: (
        <>
          Erro enquanto processávamos o pagamento no valor {values.total} em {values.dueDate} no
          cartão de crédito {values.firstDigits} *** {values.lastDigits} em {values.installments}{' '}
          parcela(s) no valor de {values.installmentAmount}. Vamos verificar a causa antes de tentar
          novamente. Caso tenha dúvidas, entre em contato com nossa equipe de atendimento.
        </>
      )
    },
    pix: {
      analysis: <></>,
      'not-authorized': <></>,
      reversed: <></>,
      created: (
        <>
          Agendamos esta cobrança no valor de {values.total} para pagamento através do Pix até dia{' '}
          {values.dueDate}.
        </>
      ),
      waiting: (
        <>
          Você deve pagar através do Pix no valor de {values.total} com vencimento até o dia{' '}
          {values.dueDate} mas chave e o QR Code possuem duração de <strong>24 horas</strong>. Caso
          o prazo tenha expirado, será necessário gerar a chave Pix novamente.
        </>
      ),
      authorized: (
        <>
          Recebemos em {values.authDate} o valor {values.total} com vencimento previsto até{' '}
          {values.dueDate}.
        </>
      ),
      'partially-refunded': (
        <>
          Fizemos o reembolso parcial da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      refunded: (
        <>
          Fizemos o reembolso total da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate} e com vencimento até o dia {values.dueDate}.
        </>
      ),
      cancelled: (
        <>
          Houve o cancelamento da cobrança do valor {values.total} e vencimento em {values.dueDate}.
        </>
      ),
      error: (
        <>
          Erro enquanto processávamos o pagamento no valor {values.total} com vencimento em{' '}
          {values.dueDate}. Vamos verificar a causa antes de tentar novamente. Caso tenha dúvidas,
          entre em contato com nossa equipe de atendimento.
        </>
      )
    },
    installmentBillingNote: {
      analysis: <></>,
      'not-authorized': <></>,
      reversed: <></>,
      created: (
        <>
          Agendamos esta cobrança no valor de {values.total} para pagamento no boleto bancário até
          dia {values.dueDate}.
        </>
      ),
      waiting: (
        <>
          Você deve pagar o primeiro boleto bancário até a data de vencimento. Caso o pagamento não
          seja realizado, este pedido será cancelado.
        </>
      ),
      authorized: (
        <>
          Recebemos em {values.authDate} o valor {values.total}.
        </>
      ),
      'partially-refunded': (
        <>
          Fizemos o reembolso parcial da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate}.
        </>
      ),
      refunded: (
        <>
          Fizemos o reembolso total da cobrança no valor {values.total} que havia sido pago em{' '}
          {values.authDate}.
        </>
      ),
      cancelled: <>Houve o cancelamento da cobrança do valor {values.total}.</>,
      error: (
        <>
          Erro enquanto processávamos o pagamento no valor {values.total}. Vamos verificar a causa
          antes de tentar novamente. Caso tenha dúvidas, entre em contato com nossa equipe de
          atendimento.
        </>
      )
    }
  }

  return <>{feedbacks[method]?.[status || '']}</>
}
